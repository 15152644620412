import {CreateDisputeReasonProps, ReasonInputType} from '@ozark/common';
import {createCheckboxListReason} from './createCheckboxListReason';
import {createCheckboxReason} from './createCheckboxReason';
import {createRichTextInputReason} from './createRichTextInputReason';
import {createTextInputReason} from './createTextInputReason';
import {DisputeReason} from './index';

export const createDisputeReason = ({
  name,
  description,
  type,
  params,
}: CreateDisputeReasonProps): DisputeReason => {
  switch (type) {
    case ReasonInputType.Text: {
      return createTextInputReason({name, description});
      break;
    }
    case ReasonInputType.RichText: {
      return createRichTextInputReason({name, description});
      break;
    }
    case ReasonInputType.CheckboxList: {
      return createCheckboxListReason({name, description, params});
      break;
    }
    default: {
      return createCheckboxReason({name, description});
    }
  }
};
